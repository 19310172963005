export const companyIndustry = [
  {
    value: 'service',
    name: '服务业',
  },
  {
    value: 'manufacture',
    name: '制造业',
  },
  {
    value: 'construction',
    name: '建筑业',
  },
  {
    value: 'farming',
    name: '农业',
  },
  {
    value: 'IT',
    name: '信息技术业',
  },
  {
    value: 'finance',
    name: '金融业',
  },
  {
    value: 'traffic',
    name: '交通运输业',
  },
];

export const companySize = [
  { value: 'mini', name: '小微企业' },
  { value: 'small', name: '中小型企业' },
  { value: 'medium', name: '中大型企业' },
  { value: 'big', name: '大型企业' },
];
